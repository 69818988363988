import React, { useState } from "react"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import HamburgerMenu from "react-hamburger-menu"
import Footer from "../Footer/Footer"
import ContentXs from "../Menu/ContentXS"
import "./NavHomeXS.scss"

const useLogo = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulLogo {
        edges {
          node {
            logo {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
        }
      }
    }
  `)

  return data.allContentfulLogo.edges[0].node
}

const NavHomeXS = () => {
  const [openHam, setOpenHam] = useState(false)
  const data = useLogo()

  const handleHamClick = () => {
    setTimeout(() => {
      setOpenHam(!openHam)
    }, 100)
  }

  const handleLinkClick = pathname => {
    const pathnames = [pathname, `${pathname}/`]
    if (pathnames.some(path => path === window.location.pathname)) {
      setOpenHam(false)
    }
  }
  return (
    <>
      <div className="NavHome__header">
        <div className="NavHome__header--logo">
          <GatsbyImage
            image={getImage(data.logo)}
            alt="Estudio"
            className="NavHome__header--logo--logo"
          />
        </div>

        <div className="NavHome__header--dobleL">
          doble<span>l</span>design
        </div>

        <HamburgerMenu
          isOpen={openHam}
          menuClicked={() => handleHamClick()}
          color="#303546"
          width={25}
          height={12}
          strokeWidth={1}
          animationDuration={0.6}
        />
      </div>

      {openHam && (
        <>
          <div
            className="NavHome__header--opened"
            onClick={() => handleHamClick()}
          >
            <Link to="/" className="NavHome__header--opened--brand">
              <GatsbyImage
                image={getImage(data.logo)}
                alt="Estudio"
                className="NavHome__header--opened--brand--logo"
              />
            </Link>

            <div>
              doble<span>l</span>design
            </div>

            <HamburgerMenu
              isOpen={openHam}
              menuClicked={() => handleHamClick()}
              color="#303546"
              width={25}
              height={12}
              strokeWidth={1}
              animationDuration={0.6}
            />

          </div>
          <div className="MenuXS__content">
            <ContentXs onClick={handleLinkClick} />
            <Footer />
          </div>
        </>
      )}
    </>
  )
}

export default NavHomeXS
