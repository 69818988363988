import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Home from "../components/Home/Home"
import Seo from "../components/seo"
import useViewport from "../Hooks/useViewport"
import useSeoQuery from "../queries/useSeoQuery"

const useHomeImgs = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulHome {
        edges {
          node {
            imgsDesktop {
              gatsbyImageData(placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
            }
            imgsMobile {
              gatsbyImageData(layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
    }
  `)

  return data.allContentfulHome.edges[0]
}

const IndexPage = () => {
  const imgs = useHomeImgs()
  const seo = useSeoQuery()
  useViewport()

  return (
    <>
      <Seo
        title="Home"
        lang="es"
        titleSEO={seo.homeTitleSeo}
        description={seo.homeDescriptionSeo.homeDescriptionSeo}
      />
      <Home
        imgsMob={imgs.node.imgsMobile}
        imgsDesktop={imgs.node.imgsDesktop}
      />
    </>
  )
}

export default IndexPage
