import React, { useState, useEffect } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import NavHomeXS from "./NavHomeXS"
import NavHomeXL from "./NavHomeXL"
import useViewport from "../../Hooks/useViewport";
import "./Home.scss"

const Home = ({ imgsMob, imgsDesktop }) => {
  const [imgMobile, setImgMob] = useState(null)
  const [imgDesk, setImgDesk] = useState(null)
  useViewport();

  useEffect(() => {
    ;(() => {
      const a = imgsMob[Math.floor(Math.random() * imgsMob.length)]
      setImgMob(a)
      const b = imgsDesktop[Math.floor(Math.random() * imgsDesktop.length)]
      setImgDesk(b)
    })()
  }, [imgsMob, imgsDesktop])

  return (
    <>
      <div className="HomeXS">
        <GatsbyImage
          image={getImage(imgMobile)}
          alt=""
          className="HomeXS--img"
        />

        <NavHomeXS />
      </div>

      <div className="HomeXL">
        <GatsbyImage
          image={getImage(imgDesk)}
          alt=""
          className="HomeXL--img"
        />
        
        <NavHomeXL />
      </div>
    </>
  )
}

export default Home