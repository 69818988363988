import React from "react"
import { Link } from "gatsby"
import "./NavHomeXL.scss"

const NavHomeXL = () => {
  return (
    <>
      <div className="HomeXL__brand">
        <h1>doble<span>l</span>design</h1>
      </div>

      <div className="HomeXL__navigation">
        <Link to="/estudio" className="HomeXL__navigation--item">
        <h1>Estudio</h1>
        </Link>
        <Link to="/proyectos" className="HomeXL__navigation--item">
          <h1>Proyectos</h1>
        </Link>
        <Link to="/reportajes" className="HomeXL__navigation--item">
        <h1>Reportajes</h1>
        </Link>
      </div>
    </>
  )
}

export default NavHomeXL
